import {
	ArrowDropUp as ArrowDropUpIcon,
	Announcement as AnnouncementIcon,
	Link as LinkIcon,
	LiveHelp as LiveHelpIcon,
	Explore as ExploreIcon,
	LocalOffer as LocalOfferIcon,
	ChevronLeft as ChevronLeftIcon,
	ChevronRight as ChevronRightIcon,
	ExpandLess as ExpandLessIcon,
	WifiTethering as WifiTetheringIcon,
	SettingsBackupRestore as SettingsBackupRestoreIcon,
	FilterList as FilterListIcon,
	Adjust as AdjustIcon,
	SettingsOverscan as SettingsOverscanIcon,
	Tune as TuneIcon,
	Visibility as VisibilityIcon,
	SettingsApplications as SettingsApplicationsIcon,
	Update as UpdateIcon,
	VpnKey as PassKeyIcon,
	Backspace as BackspaceIcon,
	Stop as StopIcon,
	Warning as AlertIcon,
	DirectionsCar as DirectionsCarIcon,
	ArrowUpward as ArrowUpwardIcon,
	ArrowDownward as ArrowDownwardIcon,
	Star as StarIcon,
	StarOutline as StarOutlineIcon,
	Cancel as CancelIcon,
	DragHandle as DragHandleIcon,
	LocationOn as LocationOnIcon,
	InfoOutlined as InfoOutlinedIcon,
	CheckCircle as CheckCircleIcon,
	Share as ShareIcon,
	BuildCircleOutlined as BuildCircleOutlinedIcon,
	HomeOutlined as HomeOutlinedIcon,
	SupervisorAccountOutlined as SupervisorAccountOutlinedIcon,
	SettingsOutlined as SettingsOutlinedIcon,
	HelpOutlineOutlined as HelpOutlineOutlinedIcon,
	ExploreOutlined as ExploreOutlinedIcon,
	ExitToAppOutlined as ExitToAppOutlinedIcon,
	PlayCircleOutline as PlayCircleOutlineIcon,
	MoreVert as MoreVertIcon,
	EditOutlined as EditOutlinedIcon,
	AddCircle as AddCircleIcon,
	RemoveCircle as RemoveCircleIcon,
	Bolt as BoltIcon,
} from "@mui/icons-material";

const icons = {
	Alert: AlertIcon,
	ArrowDropUp: ArrowDropUpIcon,
	Announcement: AnnouncementIcon,
	Link: LinkIcon,
	LiveHelp: LiveHelpIcon,
	Explore: ExploreIcon,
	LocalOffer: LocalOfferIcon,
	ChevronLeft: ChevronLeftIcon,
	ExpandLess: ExpandLessIcon,
	ChevronRight: ChevronRightIcon,
	WifiTethering: WifiTetheringIcon,
	SettingsBackupRestore: SettingsBackupRestoreIcon,
	FilterList: FilterListIcon,
	Adjust: AdjustIcon,
	Overscan: SettingsOverscanIcon,
	Tune: TuneIcon,
	Visibility: VisibilityIcon,
	SettingsApplications: SettingsApplicationsIcon,
	Update: UpdateIcon,
	PassKey: PassKeyIcon,
	Backspace: BackspaceIcon,
	Stop: StopIcon,
	DirectionsCar: DirectionsCarIcon,
	ShutterOpen: ArrowUpwardIcon,
	ShutterClose: ArrowDownwardIcon,
	FavoriteSelected: StarIcon,
	FavoriteDeSelect: StarOutlineIcon,
	Cancel: CancelIcon,
	DragHandle: DragHandleIcon,
	Location: LocationOnIcon,
	Info: InfoOutlinedIcon,
	CheckCircle: CheckCircleIcon,
	Share: ShareIcon,
	BuildCircleOutlined: BuildCircleOutlinedIcon,
	HomeOutlined: HomeOutlinedIcon,
	SupervisorAccountOutlined: SupervisorAccountOutlinedIcon,
	SettingsOutlined: SettingsOutlinedIcon,
	HelpOutlineOutlined: HelpOutlineOutlinedIcon,
	ExploreOutlined: ExploreOutlinedIcon,
	ExitToAppOutlined: ExitToAppOutlinedIcon,
	PlayCircleOutline: PlayCircleOutlineIcon,
	MoreVert: MoreVertIcon,
	EditOutlined: EditOutlinedIcon,
	AddCircle: AddCircleIcon,
	RemoveCircle: RemoveCircleIcon,
	Bolt: BoltIcon,
} as const;

export default icons;
